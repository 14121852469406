import { FiEdit } from "react-icons/fi";
import { LuTrash2 } from "react-icons/lu";
import { IoPlayOutline } from "react-icons/io5";
import { MdDone } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
// import { AiOutlinePause } from "react-icons/ai";
import { GoDotFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { tBodyItemStyle } from "../users/UserListItem";
import { useDispatch } from "react-redux";
import { modalActions } from "../../store/modalSlice";
import { useChangeListingPauseStatusMutation, useChangeListingStatusMutation } from "../../store/services/listingServices";
import { AiOutlinePause } from "react-icons/ai";
import { openModalWithTimeout } from "../../store/alertSlice";
import Checkbox from '@mui/material/Checkbox';
import { selectedListingsActions } from "../../store/selectedListingsSlice";
import { CiImageOff } from "react-icons/ci";

export const getStatusClasses = (status) => {
    if (status) {
        switch (status.toLowerCase()) {
            case 'approved':
                return 'bg-green-50 text-[#067647] border border-[#ABEFC6]';
            case 'in progress':
                return 'bg-[#FDFCE6] text-[#979550] border border-[#979550]';
            case 'rejected':
                return 'bg-red-50 text-red-700 border border-red-300';
            case 'draft':
                return 'bg-gray-200 text-gray-700 border border-gray-300';
            case 'updated':
                return 'bg-sky-100 text-sky-600 border border-sky-300';
            default:
                return 'bg-gray-100 text-gray-700 border border-gray-300';
        }
    }
};

export default function ListingListItem({ listing, isSelected }) {

    const dispatch = useDispatch();
    const [changePauseStatus] = useChangeListingPauseStatusMutation()
    const [changeListingStatus] = useChangeListingStatusMutation()

    const navigate = useNavigate()

    const mainImage = listing.images.find(image => image.is_main)

    function convertToLocaleDateString(date) {
        return new Date(date).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        }).replace(/\//g, '.');
    }

    function capitalizeFirstLetter(string) {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    //test
    const handlePauseStatus = async (e) => {
        e.stopPropagation();
        const pause_on = !listing.pause_on
        try {
            const response = await changePauseStatus({ id: listing.listing_id, data: { pause_on: pause_on } }).unwrap()
            console.log("Pause status changed:", response)
            dispatch(openModalWithTimeout({ text: 'Pause status changed successfully', type: 'success' }, 2000))
        }
        catch (error) {
            if (error.status === 403) {
                dispatch(openModalWithTimeout({ text: "Unauthorized: You do not have permission to delete this listing", type: 'error' }, 2000))
            }
            else {
                dispatch(openModalWithTimeout({ text: "Failed to change pause status", type: 'error' }, 2000))
            }
            console.log(error)
        }
    }

    const handleApproveListing = async (e) => {
        e.stopPropagation()
        const data = { newStatusValue: 'approved' }
        try {
            const response = await changeListingStatus({ id: listing.listing_id, data: data }).unwrap()
            console.log("Listing status changed:", response)
            dispatch(openModalWithTimeout({ text: 'Listing status changed successfully', type: 'success' }, 2000))
        }
        catch {
            dispatch(openModalWithTimeout({ text: 'Failed to change listing status', type: 'error' }, 2000))
        }
    }

    const handleRejectListing = async (e) => {
        e.stopPropagation()
        const data = { newStatusValue: 'rejected' }
        try {
            const response = await changeListingStatus({ id: listing.listing_id, data: data }).unwrap()
            console.log("Listing status changed:", response)
            dispatch(openModalWithTimeout({ text: 'Listing status changed successfully', type: 'success' }, 3000))
        }
        catch {
            dispatch(openModalWithTimeout({ text: 'Failed to change listing status', type: 'error' }, 3000))
        }
    }

    const handleDeleteClick = (e) => {
        e.stopPropagation()
        dispatch(modalActions.openModal({ details: { id: listing.listing_id, name: listing.title, category: 'listing' } }))
    }

    const handleListingSelect = (e) => {
        e.stopPropagation();
        dispatch(selectedListingsActions.addOrRemoveListing({ listing }))
    }

    return (
        <>
            <tr className={`table_row ${isSelected ? '!bg-blue-50' : ''}`} onClick={(e) => {
                e.stopPropagation();
                navigate(`/listings/${listing.listing_id}/edit`)
            }}>
                <td style={tBodyItemStyle} className="text-black font-medium w-[8%]">{listing.listing_id}</td>
                <td style={{ ...tBodyItemStyle, padding: '0px 20px', }} className="text-black font-medium w-[10%]">
                    <div className="rounded-xl w-[100px] h-[50px]">
                        {mainImage ? (
                            <img src={`${process.env.REACT_APP_BACKEND_HOST}/${mainImage.path}?v=${Date.now()}`} alt="img" className="rounded-xl object-cover h-full w-full" />
                        ) : (
                            listing.images[0]?.path ? (
                                <img src={`${process.env.REACT_APP_BACKEND_HOST}/${listing.images[0].path}?v=${Date.now()}`} alt="img" className="rounded-xl object-cover h-full w-full" />
                            ) : (
                                <div className="border border-gray-300 rounded-xl object-cover h-full w-full flex items-center justify-center text-xs text-gray-400">
                                    <CiImageOff size={24} />
                                </div>
                            )
                        )}
                    </div>
                </td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[20%]">
                    {listing.title}
                </td>
                <td style={tBodyItemStyle} className="w-[15%]">{convertToLocaleDateString(listing.created_at)}</td>
                <td style={tBodyItemStyle} className="w-[15%]">
                    {listing.user_type === 'individual' && `${listing.first_name} ${listing.last_name}`}
                    {listing.user_type === 'company' && `${listing.company_name}`}
                </td>
                <td style={tBodyItemStyle} className="w-[10%]">
                    <span
                        className={`flex justify-center gap-1 items-center px-2 rounded-full p-[2px] text-xs font-semibold ${getStatusClasses(listing.status)}`}
                        style={{ width: '100px' }}
                    >
                        <GoDotFill /> <span>{capitalizeFirstLetter(listing.status)}</span>
                    </span>
                </td>
                <td style={tBodyItemStyle} className="w-[15%]">
                    <div className="flex justify-center items-center gap-4 text-black">
                        {listing.status?.toLowerCase() === "approved" && (
                            listing.pause_on ? (
                                <div className="rounded-full w-8 h-8 text-center bg-primary relative cursor-pointer actions" title="Play" onClick={handlePauseStatus}>
                                    <IoPlayOutline style={{ fontSize: '18px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                </div>

                            ) : (
                                <div className="rounded-full w-8 h-8 text-center bg-primary relative cursor-pointer actions" title="Pause" onClick={handlePauseStatus}>
                                    <AiOutlinePause style={{ fontSize: '18px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                </div>
                            )
                        )}
                        {["rejected", "in progress", 'updated', 'pending'].includes(listing.status?.toLowerCase()) && (
                            <>
                                <div className="rounded-full w-8 h-8 text-center bg-primary relative cursor-pointer actions" title="Approve" onClick={handleApproveListing}>
                                    <MdDone style={{ fontSize: '18px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                </div>
                                <div className="rounded-full w-8 h-8 text-center bg-primary relative cursor-pointer actions" title="Reject" onClick={handleRejectListing}>
                                    <IoMdClose style={{ fontSize: '18px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                </div>
                            </>
                        )}
                        {['approved', 'draft'].includes(listing.status?.toLowerCase()) && (
                            <div className="rounded-full w-8 h-8 text-center bg-primary relative cursor-pointer actions" title="Edit">
                                <FiEdit style={{ fontSize: '14px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                            </div>
                        )}
                        <div className="rounded-full w-8 h-8 flex justify-center items-center text-center bg-primary relative cursor-pointer actions" title="Delete" onClick={handleDeleteClick}>
                            <LuTrash2 style={{ fontSize: '16px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                        </div>
                    </div>
                </td>
                <td style={tBodyItemStyle} className="w-[5%]">
                    <div className="text-center" onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                            size="small"
                            // sx={{
                            //     color: 'var(--primary-color)',
                            //     '&.Mui-checked': {
                            //         color: 'var(--primary-color)',
                            //     },
                            // }}
                            checked={isSelected}
                            onChange={handleListingSelect}
                        />
                    </div>
                </td>
            </tr>
        </>
    )
}