import { useEffect, useRef, useState } from "react";
import ListingList from "./ListingList";
import DashboardHeader from "../DashboardHeader";
import DashboardFilters from "../DashboardFilters";
import { useGetListingsQuery, useLazyGetListingsQuery } from "../../store/services/listingServices";
import { useNavigate } from "react-router";
import Pagination from "../Pagination";
import CircularProgress from '@mui/material/CircularProgress';
import ListingDashboardTable from "../ListingDashboardTable";
import ReactModal from "../ReactModal";
import * as XLSX from 'xlsx';
import { SiMicrosoftexcel } from "react-icons/si";


export default function ListingDashboard() {

    const [filteredListings, setFilteredListings] = useState([])
    const [filterSelected, setFilterSelected] = useState("")
    const [showFilters, setShowFilters] = useState(true)
    const [pages, setPages] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [params, setParams] = useState({})
    const [searchTextInput, setSearchTextInput] = useState('')
    const [listingsSelected, setListingsSelected] = useState([])
    const [pauseOnFilter, setPauseOnFilter] = useState("")

    useEffect(() => {
        console.log({ listingsSelected })
    }, [listingsSelected])

    const [query, setQuery] = useState(new URLSearchParams(window.location.search))

    // const { data, isLoading, isError, isSuccess } = useGetListingsQuery()

    const { data, isLoading, isFetching, isError, isSuccess } = useGetListingsQuery(params)
    const navigate = useNavigate()

    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    useEffect(() => {
        if (data) {
            console.log({ data })
            if (data.data?.length < 1) {
                setParams({ ...params })
            }
            setFilteredListings(data)
            setPages({ page: data.currentPage, pageSize: data.totalPages, total: data.totalCount })
        }
    }, [data])

    useEffect(() => {
        const newParams = {}
        query.forEach((value, key) => {
            newParams[key] = value
        })
        setParams(newParams);
        if (query.has("page")) {
            setCurrentPage(parseInt(query.get("page")))
        }
        if (query.has("status")) {
            setFilterSelected(query.get("status"))
        }
        if (query.has("title")) {
            setSearchTextInput(query.get("title"))
        }
        if (query.has("start_date")) {
            setDateRange([
                {
                    startDate: new Date(query.get("start_date")),
                    endDate: new Date(query.get("end_date")),
                    key: 'selection'
                }
            ]);
        }
    }, [query])

    useEffect(() => {
        const urlParams = new URLSearchParams(params).toString()
        navigate(`/listings?${urlParams}`)
    }, [params, navigate])


    const [isDateRangeOpen, setIsRangeDateOpen] = useState(false)

    const [dateBtnLabel, setDateBtnLabel] = useState('')

    useEffect(() => {
        const formattedStartDate = dateRange[0].startDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
        });

        const formattedEndDate = dateRange[0].endDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
        });

        setDateBtnLabel(`${formattedStartDate} - ${formattedEndDate}`)
    }, [dateRange])

    const handleIsDateRangeOpen = (isDateRangeOpen) => {
        setIsRangeDateOpen(!isDateRangeOpen)
    }

    const handlePageChange = (page) => {
        setCurrentPage(page)
        setParams({ ...params, page: page })
    }

    const handleFilterSelect = (e) => {
        const updatedParams = { ...params }
        delete updatedParams['pause_on']
        setParams({ ...updatedParams, status: e.target.id, page: 1 })
        setPauseOnFilter("")
        setCurrentPage(1)
        if (e.target.id === 'Te gjitha') {
            setFilterSelected("")
            const updatedParams = { ...params };
            delete updatedParams['status'];
            delete updatedParams['pause_on'];
            setParams(updatedParams)
            return;
        }
        setFilterSelected(e.target.innerText)
    }

    const handlePauseOnFilter = () => {
        setPauseOnFilter("on")
        setFilterSelected("Approved")
        setParams({ ...params, pause_on: 1, status: 'Approved', page: 1 })
    }

    const handlePauseOffFilter = () => {
        setPauseOnFilter("off")
        setFilterSelected("Approved")
        setParams({ ...params, pause_on: 0, status: 'Approved', page: 1 })
    }

    const handleShowFilters = () => {
        setShowFilters(!showFilters)
    }

    useEffect(() => {
        // if (!filterSelected.length) {
        //     setFilteredListings(data)
        //     return;
        // }
        // if (filterSelected) {
        //     setFilteredListings(data.listings.filter(listing => listing.status === filterSelected.toLowerCase()))
        // }
    }, [filterSelected])

    const handleListingsSelected = (selectedListing) => {
        const updatedListingsSelected = [...listingsSelected]
        if (listingsSelected.includes(selectedListing)) {
            updatedListingsSelected.filter(listing => listing !== selectedListing)
            setListingsSelected(updatedListingsSelected)
            return;
        }
        updatedListingsSelected.push(selectedListing)
        setListingsSelected(updatedListingsSelected)
    }

    const debounceTimeoutRef = useRef(null);

    const handleSearchTextInputChange = (e) => {
        const text = e.target.value
        setSearchTextInput(text)

        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        if (text.length > 2) {
            debounceTimeoutRef.current = setTimeout(() => {
                setParams({ ...params, title: text })
            }, 500)
        }
        else {
            const updatedParams = { ...params }
            delete updatedParams['title']
            setParams(updatedParams)
        }
    }

    const handleDateRangeChange = (item) => {
        setDateRange([item.selection])
        const start_date = item.selection.startDate || null
        const end_date = item.selection.endDate || null

        const start_year = start_date.getFullYear();
        const start_month = String(start_date.getMonth() + 1).padStart(2, '0');
        const start_day = String(start_date.getDate()).padStart(2, '0');
        const formattedStartDate = `${start_year}-${start_month}-${start_day}`;

        const end_year = end_date.getFullYear();
        const end_month = String(end_date.getMonth() + 1).padStart(2, '0');
        const end_day = String(end_date.getDate()).padStart(2, '0');
        const formattedEndDate = `${end_year}-${end_month}-${end_day}`;
        setParams({ ...params, start_date: formattedStartDate, end_date: formattedEndDate })
    }

    const removeDateFilter = () => {
        const updatedParams = { ...params };
        delete updatedParams['start_date'];
        delete updatedParams['end_date'];
        setParams(updatedParams)
        setIsRangeDateOpen(false)
        setDateRange([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            }
        ])
        return;
    }
    const [trigger, { data: listingData }] = useLazyGetListingsQuery(params);

    const handleExtractDataClick = async () => {
        let allListings = [];
        let page = 1;
        let hasMoreData = true;

        while (hasMoreData) {
            const { data, error } = await trigger({ limit: 100000 });

            if (error) {
                console.error('Failed to fetch listing data:', error);
                return;
            }

            if (data?.data) {
                allListings = [...allListings, ...data.data];

                hasMoreData = data?.meta?.totalPages > page;

                if (hasMoreData) {
                    page++;
                }
            } else {
                console.error("No listing data found.");
                break;
            }
        }

        if (allListings.length > 0) {
            const formattedData = allListings.map((listing) => ({
                "Listing ID": listing.id,
                "User ID": listing.user_id,
                "Property Type ID": listing.property_type_id,
                "Sub Property Type ID": listing.sub_property_type_id,
                "Location ID": listing.location_id,
                "Title": listing.title,
                "Type": listing.type,
                "Rent Type": listing.rent_type,
                "Surface": listing.surface,
                "Measurement Unit": listing.measurement_unit,
                "Price": listing.price,
                "Price Unit": listing.price_unit,
                "Address": listing.address,
                "Construction Year": listing.construction_year,
                "Floor": listing.floor,
                "Num Rooms": listing.num_rooms,
                "Num Bathrooms": listing.num_bathrooms,
                "Character IDs": listing.character_ids,
                "Heating IDs": listing.heating_ids,
                "Furnishing IDs": listing.furnishing_ids,
                "Directions": listing.directions,
                "Latitude": listing.latitude,
                "Longitude": listing.longitude,
                "Title Deed": listing.title_deed ? "Yes" : "No",
                "PDF Path": listing.pdf_path || "N/A",
                "Description": listing.description,
                "Steps": listing.steps,
                "Status": listing.status,
                "Pause On": listing.pause_on ? "Yes" : "No",
                "Created At": listing.created_at,
                "Updated At": listing.updated_at,
                "Deleted At": listing.deleted_at || "N/A",
            }));


            const ws = XLSX.utils.json_to_sheet(formattedData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Listings");


            XLSX.writeFile(wb, "Prona Listings.xlsx");
        } else {
            console.error("No listing data to export.");
        }
    };

    const buttons = [
        {
            label: "Extract data",
            color: "bg-white",
            border: "border border-[#D0D5DD]",
            shadow: "shadow-sm",
            onClick: handleExtractDataClick,
            linkTo: null,
            icon: <SiMicrosoftexcel size={16} />


        },

        { label: 'Archived', color: 'bg-white', border: 'border border-[#D0D5DD]', shadow: 'shadow-sm', onClick: null, linkTo: '/listings/archived' }
    ]

    const details = {
        title: 'Listings',
        count: data?.totalCount
    }

    const tableData = {
        headLabels: ['Listing ID', 'Listing Image', 'Listing Name', 'Created Date', 'User', 'Status'],
        list: <ListingList listings={filteredListings?.data} />,
        data: filteredListings?.data,
        listingsSelected: listingsSelected,
        handleListingsSelected: handleListingsSelected
    }

    const filters = {
        categories: ['Approved', 'Pending', 'Draft', 'Rejected', 'Updated'],
        selectedFilter: filterSelected,
        handleFilterSelect: handleFilterSelect,
        showFilters: showFilters,
        handleShowFilters: handleShowFilters,
        dateRange: dateRange,
        setDateRange: setDateRange,
        isDateRangeOpen: isDateRangeOpen,
        handleIsDateRangeOpen: handleIsDateRangeOpen,
        dateBtnLabel: dateBtnLabel,
        handleDateRangeChange: handleDateRangeChange,
        removeDateFilter: removeDateFilter,
        params: params,
        updatedStatusCount: filteredListings?.updatedStatusCount,
        searchTextInput: searchTextInput,
        handleSearchTextInputChange: handleSearchTextInputChange,
        handlePauseOnFilter: handlePauseOnFilter,
        handlePauseOffFilter: handlePauseOffFilter,
        pauseOnFilter: pauseOnFilter
    }

    // if (isFetching) return (
    //     <>\
    // <div className="w-full h-screen flex items-center justify-center text-primary"><CircularProgress color="var(--primary-color)" /></div>
    //     </>
    // )
    if (isError) return <div>Error loading listings</div>

    return (
        <div className="h-full">
            <div className="h-full flex flex-col justify-between">
                <div>
                    <ReactModal />
                    <DashboardHeader buttons={buttons} details={details} />
                    <hr />
                    <DashboardFilters filters={filters} />
                    <div className="w-full relative">
                        {isLoading ? (
                            <div className="w-full z-30 absolute top-0 left-0 bg-transparent backdrop-blur-sm h-[800px] flex items-center justify-center text-primary">
                                <CircularProgress thickness={6} size={40} color="var(--primary-color)" />
                            </div>
                        ) : (
                            isFetching && (
                                <div className="w-full z-30 absolute top-0 left-0 bg-transparent backdrop-blur-sm h-full flex items-center justify-center text-primary">
                                    <CircularProgress thickness={6} size={40} color="var(--primary-color)" />
                                </div>
                            )
                        )}
                        <ListingDashboardTable tableData={tableData} />
                    </div>
                </div>
                {!isLoading && <Pagination currentPage={currentPage} totalPages={Math.ceil(data?.totalCount / 10)} onPageChange={handlePageChange} />}
            </div>
        </div>
    )
}