import { BiPlus } from "react-icons/bi";
import { useEffect, useRef, useState } from "react";
import UserList from "./UserList";
import DashboardHeader from "../DashboardHeader";
import DashboardTable from "../DashboardTable";
import DashboardFilters from "../DashboardFilters";
import { useGetAllUsersQuery, useGetUsersQuery, useLazyGetUsersQuery } from "../../store/services/userServices";
import { useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../Pagination";
import CircularProgress from '@mui/material/CircularProgress';
import ReactModal from '../ReactModal'
import * as XLSX from 'xlsx';
import { SiMicrosoftexcel } from "react-icons/si";


export default function UserDashboard() {

    // const { data, isLoading, isError, isSuccess } = useGetUsersQuery();

    const [filteredUsers, setFilteredUsers] = useState([
        {
            id: '',
            name: '',
            created_at: '',
            phone_number: '',
            email: '',
            role_type: '',
            user_type: '',
        },
    ])

    const [filterSelected, setFilterSelected] = useState("")
    const [showFilters, setShowFilters] = useState(true)
    const navigate = useNavigate()
    const [pages, setPages] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [params, setParams] = useState({})
    const [allUsersData, setAllUsersData] = useState([])
    const [searchTextInput, setSearchTextInput] = useState('')

    const [query, setQuery] = useState(new URLSearchParams(window.location.search))

    const { data, isLoading, isFetching, isError, isSuccess } = useGetUsersQuery(params);

    useEffect(() => {
        if (data) {
            console.log({ data })
            setAllUsersData(data)
            setPages({ currentPage: data.currentPage, totalPages: data.totalPages, totalCount: data.totalCount })
        }
    }, [data])

    useEffect(() => {
        if (allUsersData) setFilteredUsers(allUsersData)
    }, [allUsersData])

    useEffect(() => {
        const newParams = {}
        query.forEach((value, key) => {
            newParams[key] = value
        })
        setParams(newParams);
        if (query.has("page")) {
            setCurrentPage(parseInt(query.get("page")))
        }
        if (query.has("user_type")) {
            setFilterSelected(query.get("user_type"))
        }
        if (query.has("q")) {
            setSearchTextInput(query.get("q"))
        }
    }, [query])

    useEffect(() => {
        const urlParams = new URLSearchParams(params).toString()
        navigate(`/users?${urlParams}`)
    }, [params, navigate])

    const handleShowFilters = () => {
        setShowFilters(!showFilters)
    }

    const handlePageChange = (page) => {
        setCurrentPage(page)
        setPages({ ...pages, currentPage: page })
        setParams({ ...params, page: page })
    }

    const handleFilterSelect = (e) => {
        setParams({ ...params, user_type: e.target.id, page: 1 })
        if (e.target.id === 'Te gjitha') {
            setFilterSelected("")
            const updatedParams = { ...params };
            delete updatedParams['user_type'];
            setParams(updatedParams)
            return;
        }
        setFilterSelected(e.target.innerText)
    }

    const debounceTimeoutRef = useRef(null);

    const handleSearchTextInputChange = (e) => {
        const text = e.target.value
        setSearchTextInput(text)

        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        if (text.length > 2) {
            debounceTimeoutRef.current = setTimeout(() => {
                setParams({ ...params, q: text })
            }, 500)
        }
        else {
            const updatedParams = { ...params }
            delete updatedParams['q']
            setParams(updatedParams)
        }
    }

    useEffect(() => {
        // if (!filterSelected.length) {
        //     setFilteredUsers(users)
        //     return;
        // }
        // setFilteredUsers(filteredUsers.filter(user => user.userType === filterSelected))
    }, [filterSelected])

    const [trigger, { data: userData }] = useLazyGetUsersQuery();

    const handleExtractDataClick = async () => {
        let allUsers = [];
        let page = 1;
        let hasMoreData = true;

        while (hasMoreData) {
            const { data, error } = await trigger({ limit: 100000 });

            if (error) {
                console.error('Failed to fetch user data:', error);
                return;
            }

            if (data?.data) {
                allUsers = [...allUsers, ...data.data];

                hasMoreData = data?.meta?.totalPages > page;

                if (hasMoreData) {
                    page++;
                }
            } else {
                console.error("No user data found.");
                break;
            }
        }

        if (allUsers.length > 0) {
            const formattedData = allUsers.map((user) => ({
                "User ID": user.id,
                "Email": user.email,
                "Password": user.password,
                "User Type": user.user_type,
                "User Role": user.user_role,
                "Provider ID": user.provider_id || "N/A",
                "Provider": user.provider || "N/A",
                "Is Verified": user.isVerified ? "Yes" : "No",
                "Created At": user.created_at,
                "Updated At": user.updated_at,
                "Deleted At": user.deleted_at || "N/A",
            }));

            const ws = XLSX.utils.json_to_sheet(formattedData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Users");

            XLSX.writeFile(wb, "Prona Users.xlsx");
        } else {
            console.error("No user data to export.");
        }
    };



    const buttons = [
        {
            label: "Extract data",
            color: "bg-white",
            border: "border border-[#D0D5DD]",
            shadow: "shadow-sm",
            onClick: handleExtractDataClick,
            linkTo: null,
            icon: <SiMicrosoftexcel size={16} />


        },
        { label: 'Add user', color: 'bg-gradient-to-t from-[#E2EB70] to-[#E8EF8A]', border: 'border border-b-2 border-primary', icon: <BiPlus />, shadow: 'shadow-md', onClick: null, linkTo: '/users/add' }
    ]

    const details = {
        title: 'Users',
        count: data?.totalCount
    }

    const tableData = {
        headLabels: ['Name & Surname', 'Created Date', 'Phone number', 'Email', 'User Type'],
        list: <UserList users={filteredUsers?.data} />,
        data: filteredUsers?.data,
        actions: true
    }

    const filters = {
        categories: ['Company', 'Individual'],
        selectedFilter: filterSelected,
        handleFilterSelect: handleFilterSelect,
        showFilters: showFilters,
        handleShowFilters: handleShowFilters,
        searchTextInput: searchTextInput,
        handleSearchTextInputChange: handleSearchTextInputChange
    }

    return (
        <div className="h-full">
            <div className="h-full flex flex-col justify-between">
                <div>
                    <ReactModal />
                    <DashboardHeader buttons={buttons} details={details} />
                    <hr />
                    <DashboardFilters filters={filters} />
                    <div className="w-full relative">
                        {isLoading ? (
                            <div className="w-full z-30 absolute top-0 left-0 bg-transparent backdrop-blur-sm h-[800px] flex items-center justify-center text-primary">
                                <CircularProgress thickness={6} size={40} color="var(--primary-color)" />
                            </div>
                        ) : (
                            isFetching && (
                                <div className="w-full z-30 absolute top-0 left-0 bg-transparent backdrop-blur-sm h-full flex items-center justify-center text-primary">
                                    <CircularProgress thickness={6} size={40} color="var(--primary-color)" />
                                </div>
                            )
                        )}
                        <DashboardTable tableData={tableData} />
                    </div>
                </div>
                {!isLoading && <Pagination currentPage={currentPage} totalPages={Math.ceil(data?.totalCount / 10)} onPageChange={handlePageChange} />}
            </div>
        </div>
    )
}