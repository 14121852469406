import { LuTrash2 } from 'react-icons/lu'
import { useDispatch } from 'react-redux'
import { modalActions } from '../../store/modalSlice'
import { tBodyItemStyle } from '../users/UserListItem'
import { FiEdit } from 'react-icons/fi'
import { editItemModalActions } from '../../store/editItemModalSlice'

export default function SubPropertyListItem({ item, category }) {

    const dispatch = useDispatch()

    const handleDelete = (e) => {
        e.stopPropagation();
        dispatch(modalActions.openModal({ details: { id: item.sub_property_id, name: item.sub_property_type, category: category } }))
    }

    const handleEdit = () => {
        dispatch(editItemModalActions.openModal({ details: { id: item.sub_property_id, name: item.sub_property_type, category: category, property_id: item.property_id } }))
    }

    return (
        <>
            <tr className='table_row' onClick={handleEdit}>
                <td style={tBodyItemStyle} className="text-black font-medium w-[45%]">
                    <div className='flex items-center gap-3'>
                        <div>{item.sub_property_type}</div>
                        {/* {item.image_path && (
                                <div>
                                    <img src={`${process.env.REACT_APP_BACKEND_HOST}/${item.image_path}`} alt='img' width={18} height={18} />
                                </div>
                            )} */}
                    </div>
                </td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[40%]">
                    <div className='flex items-center gap-3'>
                        <div>{item.property_type}</div>
                        {/* {item.image_path && (
                                <div>
                                    <img src={`${process.env.REACT_APP_BACKEND_HOST}/${item.image_path}`} alt='img' width={18} height={18} />
                                </div>
                            )} */}
                    </div>
                </td>
                <td style={tBodyItemStyle} className='w-[15%]'>
                    <div className="flex justify-center gap-4 text-black">
                        <div className="rounded-full w-8 h-8 text-center bg-primary relative cursor-pointer actions" title="Edit">
                            <FiEdit style={{ fontSize: '14px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                        </div>
                        <div className="rounded-full w-8 h-8 flex justify-center items-center text-center bg-primary relative cursor-pointer actions" onClick={handleDelete}>
                            <LuTrash2 style={{ fontSize: '16px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )
}